"use strict";
// import functions
import FocusAccessibility from "./modules/focus_accessibility";
import TypeRatioInterpolation from "./modules/type_ratio_interpolation";
import RephrainMap from "./modules/rephrain_map";
import API from "./modules/api";
import LoginState from "./modules/login_state";
import LoginAvatar from "./modules/login_avatar";
import UserList from "./modules/user_list";
import UserDetail from "./modules/user_detail";
import ProposeNewResource from "./modules/propose_new_resource";

/*
    Custom elemements for editing
*/
customElements.define("login-state", LoginState);
customElements.define("login-avatar", LoginAvatar);
customElements.define("user-list", UserList);
customElements.define("user-detail", UserDetail);
customElements.define("propose-new-resource", ProposeNewResource);

// Capture any OAuth logic
API.handleAuth();

// Store API on the global scope for development
window.API = window.API || API;

/*
    interpolate between type ratio minimum and maximum values,
    and store the result in a CSS custom property
*/
TypeRatioInterpolation();
/*
    handle display of map data
*/
RephrainMap();
/*
    allow enchanced focus detection (depends on a11y.js)
*/
FocusAccessibility();
